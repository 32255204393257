<template>
    <div>
        <TableWs :add="true" @add="add()" :tableTitle="tableTitle" :delete-url="deleteUrl" :isAssess="true"
               :pageFooter="true"
               :InfoHref="InfoHref" :deleteUrl="deleteUrl"
               :searchUrl="url" :statistics="statistics" :statis-href="`AssessStatistics`"
               :startUrl="startUrl" :suspendUrl="suspendUrl" :endUrl="endUrl"
               :statistics_="true"
               ref="table" :state_title="state_title" @addCon="addConChange">
        </TableWs>

        <el-dialog class="new-dialog" title="新建测评" :visible.sync="dialogFormVisible" width="30vw"  @close="onDialogClose">
            <el-form :model="addForm">
                <el-form-item label="选择患者" label-width="140px">
                    <el-select placeholder="请选择患者"
                               v-model="patientListName"
                               filterable :filter-method="dataFilter"
                               @visible-change="visibleHideSelectInput"
                               @change="onPatientChange($event)"
                               clearable
                    >
                        <el-option v-for="item in deviceIdList"
                                   :key="item.id"
                                   :value="item.id"
                                   :label="item.name +'  '+ item.age+'岁'+'  '+item.medical_num"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
<!--                <el-button @click="dialogFormVisible = false" round style="background-color: #8197FE; border-color: #8197FE;color: #FFFFFF;">取 消</el-button>-->
                <el-button type="primary" @click="onAdd" round style="background-color: #7DB167; border-color: #7DB167;">开始测评</el-button>
            </div>
        </el-dialog>
        <!--是否继续答题-->
        <el-dialog :visible.sync="exportShow" :modal="false" width="22vw" top="25vh" :close-on-click-modal="false">
            <div class="dialog-body">
                <div class="dialog-text" style="text-align: center">
                    <img src="../assets/images/tishi.png" style="width: 80px;height: 80px">
                    <h2 style="margin-top: 10px;margin-bottom: 10px;">提示</h2>
                    <!--                  <div style="margin-top: 5px;" v-for="(item,index) in delinfoList" >-->
                    <!--                      <h3>{{ item.name }}{{item.text}}，是否继续删除?</h3>-->
                    <!--                  </div>-->
                    <div style="margin-top: 5px;margin-bottom: 30px" >
                        <h3>是否继续测评？</h3>
                    </div>
                    <div slot="footer">
                        <el-button type="warning" style="background: #ffffff;border: 1px solid #CDCDCD;color: #333333;box-shadow:none;" round @click="exportShow = false">取消
                        </el-button>
                        <el-button type="primary" round @click="exportClick">继续测评</el-button>
                    </div>
                </div>
            </div>

        </el-dialog>
    </div>

</template>

<script>
import Table from '../components/Table';
import TableWs from "../components/TableWs";
export default {
    name: "Assess",
    components: {
        TableWs,Table
    },
    data() {
        return {
            treeKey: new Date().getTime()+'',
            tableTitle: [
                {
                    name: '患者姓名',
                    value: 'name',
                    width: 180
                },
                {
                    name: '测试时间',
                    value: 'create_at',
                    width: 250
                },
                {
                    name: '状态',
                    value: 'status_name',
                    width: 130
                }
            ],
            startUrl: 'api/TrainExam/start',
            suspendUrl: 'api/TrainExam/suspend',
            endUrl: 'api/TrainExam/end',
            url: 'api/TrainExam/index',
            deleteUrl: 'api/TrainExam/delete',
            addHref: 'AddAssess',
            InfoHref: 'AssessDetail',
            dialogFormVisible: false,
            state_title: true,
            addForm: {
                name: ''
            },
            patientList: [],
            patientListName: '',
            patient_id: '',
            terminal_id: '',
            statistics: true,
            deviceIdList:[],
            exportShow:false,
            infoForm:{}
        };
    },
    mounted() {
        this.onPatient();
    },
    computed: {},
    watch: {},
    methods: {
        /**
         * 继续测评点击
         * */
        exportClick() {
            this.$router.push({
                name: 'TerminalAnswer',
                query: {
                    patient_id: this.infoForm.patient_id,
                    exam_id:this.infoForm.id,
                }
            });
            // this.$electron.ipcRenderer.invoke('win-subScreen', {
            //     open: true,
            //     path: '#'
            // })
        },
        /**
         * 开始测评点击
         * */
        async onAdd() {
            if(this.patient_id == '') {
                this.$message.error('请先选择测评患者');
                return;
            }
            await this.$axios.post('api/TrainExam/add', {
                patient_id:this.patient_id
            }).then(res => {
                if (res.data.code === 1) {
                    this.dialogFormVisible = false
                    this.Refresh()
                    this.$router.push({
                        name: 'TerminalAnswer',
                        query: {
                            patient_id: this.patient_id,
                            exam_id:res.data.data.exam_id
                        }
                    });
                    // this.$electron.ipcRenderer.invoke('win-subScreen', {
                    //     open: true,
                    //     path: '#'
                    // })
                }
            })
        },
        /**
         * 刷新
         * */
        Refresh() {
            this.$refs.table.onRefresh()
        },
        // 自定义筛选方法
        dataFilter(val) {
            if (val) {
                let filterResult = [];
                let originalData = JSON.parse(JSON.stringify(this.patientList));
                originalData.filter((item) => {
                    if (item.medical_num.includes(val) || item.name.includes(val)) {
                        filterResult.push(item);
                    }
                })
                this.deviceIdList = filterResult

            } else {
                this.deviceIdList = this.patientList;
            }
        },
        // 当下拉框出现时触发
        visibleHideSelectInput(val) {
            if(val) {
                this.deviceIdList = JSON.parse(JSON.stringify(this.patientList));
            }
        },
        onDialogClose() {
            this.patientListName = '';
        },

        onPatientChange(e) {
            this.patient_id = e
            this.patient_id_name = e ? this.patientList.find(ele => ele.id === e).name : ''
            console.log(this.patient_id_name)
        },

        async onPatient() {
            await this.$axios.post('api/patient/index').then(res => {
                if (res.data.code == 1) {
                    this.patientList = res.data.data.list
                }
            })
        },
        addConChange(val) {
            this.infoForm = val
            this.exportShow = true
        },
        add() {
            this.dialogFormVisible = true

        },
    }
    ,
}
</script>
<style>
.dialog {
    width: 80%;
    height: 75%;
}

@media (max-width: 1024px) {
    .dialog {
        width: 767px;
    }
}
</style>

<style scoped lang="scss">

.dialog-form-title {
    line-height: 40px;
}

.dialog-body {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 200px;
        height: 200px;
    }
}

.dialog-text {
    //margin-left: 50px;
    //width: 13em;
    color: #333333;

    > h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }

    > p {
        font-size: 16px;
    }
}

.new-evaluation {
    margin: 0 20px;
    border-radius: 4px;
    border: 1px solid #7DB167;
}

.new-evaluationTitle {
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    margin-left: 20px;
}

.evaluationBtn {
    margin-right: 50px;
    margin-bottom: 10px;
}

::v-deep .el-table__body-wrapper {
    height: 100%;
    overflow: auto;
}
::v-deep {
    .el-select {
        width: 280px;
    }
    .el-dialog__header {
        text-align: left;
        //border-bottom: 1px solid #E8E8E8;
        font-size: 28px;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        padding-left: 50px;
    }
    .el-form-item__label {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
    }
}
//.time-select {
//    border-bottom: 1px solid #cccccc;
//    display: flex;
//    align-items: center;
//    font-size: 17px;
//
//    span {
//        font-weight: bold;
//        color: #000000;
//        margin-bottom: 6px;
//    }
//}

//.input-suffix {
//    margin-left: 20px;
//    display: flex;
//    width: 150px;
//    white-space: nowrap;
//    align-items: flex-end;
//
//    ::v-deep .el-input__inner {
//        border-top: none;
//        border-left: none;
//        border-right: none;
//        line-height: 20px;
//        height: 20px;
//        text-align: center;
//    }
//}


.el-table__body-wrapper {
    height: 100%;
    overflow: auto;
}

.el-table--border {
    width: 100%;
    height: 193px;
    overflow: auto;
}

//.gauga {
//    font-size: 24px;
//    font-weight: 600;
//    color: #333333;
//    line-height: 33px;
//}
//
//.gauga_title {
//    margin: 0 0 10px 30px;
//}
//
//
//.gauga_content {
//    border-radius: 2px;
//    border: 1px solid #cdcdcd;
//    margin-left: 30px;
//}

.content_top {
    margin: 15px;
}

.content_body {
    overflow: auto;
    height: 235px;
    padding: 0 17px;
}

//.demo-autocomplete {
//    width: 100%;
//}

//.gauga_contentTop {
//    height: 50px;
//    border-bottom: 1px solid #cccccc;
//    display: flex;
//    align-items: center;
//    padding-left: 40px;
//}
::v-deep {
    .el-select .el-input__inner:focus {
        border-color: #7DB167;
    }
    .el-select .el-input.is-focus .el-input__inner {
        border-color: #7DB167;
    }
    .el-radio__input.is-checked + .el-radio__label {
        color: #7DB167;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #7DB167;
        background-color: #7DB167;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
        border-color: #7DB167;
        background-color: #7DB167;
    }
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        border-color: #7DB167;
        background-color: #7DB167;
    }
    .el-button--warning {
        border-color: #7DB167;
        background-color: #7DB167;
    }
    .el-button--primary {
        border-color: #7DB167;
        background-color: #7DB167;
    }

}
//.new-evaluationPatient_add {
//    width: 100px;
//    height: 35px;
//    background: #62B5FB;
//    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.02);
//    border-radius: 2px;
//    font-size: 16px;
//    font-weight: bold;
//    color: #ffffff;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    border: none;
//    margin-left: 10px;
//    margin-top: 2px;
//}

.content_body::-webkit-scrollbar, .el-table__body-wrapper::-webkit-scrollbar {
    display: block;
    width: 9px;
    height: 9px;
}

.content_body::-webkit-scrollbar-thumb, .el-table__body-wrapper::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #D8D8D8;;
}

.content_body::-webkit-scrollbar-track, .el-table__body-wrapper::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    background: #F7F7F7;
    border-radius: 10px;
}
</style>
